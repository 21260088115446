/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import Helmet from 'react-helmet'

import Footer from './footer'
import Navbar from './navbar'
import ContactForm from './contactForm'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet title={data.site.siteMetadata.title}>
        <link href="https://fonts.googleapis.com/css?family=Raleway:400,600,700,800&display=swap" rel="stylesheet" />
      </Helmet>
      <Global
        styles={theme => css`
          body {
            margin: 0;
            font-family: ${theme.fonts.text};
          }
          html {
            scroll-behavior: smooth;
          }
        `}
      />
      <Navbar />
      <main>{children}</main>
      <ContactForm />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
