import React from 'react'
import ReactModal from 'react-modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'fit-content',
    maxWidth: '75vw',
    maxHeight: '75vh',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFC',
  },
}

export default function Modal(props) {
  return <ReactModal style={customStyles} {...props} />
}
