import React from 'react'
import { Box } from 'rebass'

import BackgroundImage from 'gatsby-background-image'

export default function Headline({ fluidBackgroundImage, children }) {
  return (
    <>
      <Box backgroundColor="dark-grey" height={['12rem', null, '24rem']} />

      <Box marginTop={['-10rem', null, '-22rem']} marginBottom={5} backgroundColor="soft-black" mx="2rem">
        <BackgroundImage fluid={fluidBackgroundImage} backgroundColor="dark-grey" backgroundSize="100% auto">
          <Box py={[5, null, '11rem']} px={[4, null, '16rem']} backgroundColor="rgba(0, 0, 0, 0.7)" color="white">
            {children}
          </Box>
        </BackgroundImage>
      </Box>
    </>
  )
}
