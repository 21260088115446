import React from 'react'
import { Box } from 'rebass'

export default function Burger({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 60 60"
      width={size}
      height={size * (20 / 24)}
      fill="currentcolor"
      {...props}
    >
      <title>Burger</title>
      <g>
        <path d="M3.161,6.322C1.416,6.322,0,4.906,0,3.161l0,0C0,1.415,1.416,0,3.161,0l0,0h52.68      c1.745,0,3.161,1.415,3.161,3.161l0,0c0,1.745-1.416,3.161-3.161,3.161l0,0H3.161L3.161,6.322z" />

        <path d="M3.162,27.394C1.415,27.394,0,25.979,0,24.232l0,0c0-1.745,1.415-3.161,3.161-3.161l0,0h52.679      c1.745,0,3.16,1.416,3.16,3.161l0,0c0,1.746-1.415,3.161-3.16,3.161l0,0H3.162L3.162,27.394z" />

        <path d="M3.162,48.465C1.415,48.465,0,47.049,0,45.303l0,0c0-1.745,1.415-3.16,3.162-3.16l0,0H55.84      c1.746,0,3.162,1.415,3.162,3.16l0,0c0,1.746-1.416,3.162-3.162,3.162l0,0H3.162L3.162,48.465z" />
      </g>
    </Box>
  )
}
