import { Box } from 'rebass'
import React from 'react'

export default function Hr({ center, color = 'white', sx, ...props }) {
  return (
    <Box
      as="hr"
      sx={{
        border: 'none',
        borderBottom: `solid 2px`,
        borderColor: color,
        width: center ? '100%' : null,
        maxWidth: ['100%', '14rem'],
        my: 4,
        ...sx,
      }}
      {...props}
    />
  )
}
