import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { css, Global } from '@emotion/core'

export default function Parallax({ fluid, children }) {
  return (
    <>
      <Global
        styles={theme => css`
          .parallax,
          .parallax::before,
          .parallax::after {
            background-attachment: fixed;
          }

          @media screen and (max-width: 800px) {
            .parallax,
            .parallax::before,
            .parallax::after {
              background-attachment: scroll;
            }
          }
        `}
      />
      <BackgroundImage
        fluid={fluid}
        className="parallax"
        // style={{ backgroundAttachment: 'fixed' }}
      >
        {children}
      </BackgroundImage>
    </>
  )
}
