import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Text } from 'rebass'
import { Input, Textarea } from '@rebass/forms'
import { graphql, useStaticQuery } from 'gatsby'

import Help from './icons/help'
import Modal from './modal'
import Container from './container'
import Parallax from './parallax'
import * as fieldNames from './get-a-quote/field-names'

const Subheading = props => <Heading as="h4" mt={3} fontSize={3} {...props} />

export default function ContactForm() {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "map-and-window.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)

  return (
    <Parallax fluid={data.backgroundImage.childImageSharp.fluid}>
      <Box display="flex" flexDirection="column" backgroundColor="rgba(0,0,0,0.45)" color="white" py={6}>
        <Flex justifyContent="center">
          <Box as="form" method="POST" action="/thank-you" data-netlify="true" display="block" name="quick-quote">
            <Heading as="h2" variant="sectionSubheading" fontSize="5" mb={4} textAlign="center">
              Want to work with me?
            </Heading>

            <Box display="none">
              {Object.values(fieldNames).map(name => (
                <input key={name} name={name} />
              ))}
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridGap: 3,
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                px: 3,
              }}
            >
              <Input name="name" placeholder="Name" />
              <Input name="company" placeholder="Company" />
              <Input name="email" placeholder="Email*" type="email" required />
              <Input name="phone" placeholder="Phone" type="tel" />
            </Box>

            <Box
              sx={{
                position: 'relative',
                p: 3,
              }}
            >
              <Textarea name="description" placeholder="Tell me about your project!" height={200} />
              <Button
                type="button"
                sx={{
                  position: 'absolute',
                  bottom: 24,
                  right: 24,
                  display: 'flex',
                  padding: 1,
                  backgroundColor: 'transparent',
                }}
                onClick={() => setIsHelpModalOpen(true)}
              >
                <Help color="gold" />
              </Button>
              <Modal isOpen={isHelpModalOpen} onRequestClose={() => setIsHelpModalOpen(false)}>
                <Container p={[3, 4]}>
                  <Heading as="h3" variant="sectionSubheading">
                    What information do I need from you?
                  </Heading>
                  <Text sx={{ em: { fontWeight: 700, fontStyle: 'normal' } }}>
                    <Text as="p" mb={3}>
                      Hey there, and thanks in advance for getting in touch, I’m looking forward to hearing from you!
                    </Text>

                    <Text as="p">
                      If you’re uncertain about what information to include, there are a couple of things which are
                      always helpful for me to hear about, which I’ve listed for you below. Don’t worry if you don’t
                      have it all to hand right now, but if you do then go ahead and pop it in your message.
                    </Text>

                    <Subheading as="h4">Translation</Subheading>
                    <Box as="ul">
                      <Text as="li">
                        <em>Word count.</em> I love word counts; I can do all sorts of maths with them to get quotes and
                        timelines through to clients – and without them I really struggle to give estimates.
                      </Text>
                      <Text as="li">
                        <em>Subject matter.</em> Whatever you’re getting in touch about, try to narrow the subject
                        matter down (eg. finance, legal, creative). It gives me a clearer idea of the task at hand.
                      </Text>
                    </Box>

                    <Subheading as="h4">Subtitling</Subheading>
                    <Box as="ul">
                      <Text as="li">
                        <em>Minutes.</em> Have you got video files which are in need of subtitles? If so, do please let
                        me know how long they are, and how many of them there are. Again, super useful for me!
                      </Text>
                      <Text as="li">
                        <em>The three Ts.</em> There are three steps in the creation of a subtitle file, which aren’t
                        always done together. Transcription, time-coding & translation – let me know which ones.
                      </Text>
                    </Box>

                    <Subheading as="h4">Localisation & Copywriting</Subheading>
                    <Box as="ul" mb={3}>
                      <Text as="li">
                        <em>Industry.</em> Is your content being created or translated for a specific industry? Jot it
                        down and I’ll have a better idea of the research necessary.
                      </Text>
                      <Text as="li">
                        <em>Company.</em> Are you bringing your products or services to the land of Norway? Tell me a
                        bit about your company so I can get the drift of what you need.
                      </Text>
                      <Text as="li">
                        <em>T&Cs.</em> If you need a localisation service, then do please include whether or not you
                        need the terms and conditions translated as well. Don’t forget the word count!
                      </Text>
                    </Box>

                    <Text as="p">
                      Last but not least, do please include a ballpark number for your budget or rates. Localisation and
                      copywriting services are charged by the hour, subtitling per minute and translation per word.
                    </Text>
                  </Text>
                </Container>
              </Modal>
            </Box>

            <Flex width={1} justifyContent="flex-end" px={3}>
              <Button type="submit" width={['100%', 'unset']}>
                Send
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Parallax>
  )
}
