import React from 'react'
import { Box, Heading, Text, Link } from 'rebass'

import Email from './icons/email'
import LinkedIn from './icons/linkedin'

const IconLink = props => (
  <Link
    color="white"
    display="block"
    p="1.5rem"
    mx="1"
    sx={{
      border: '1px solid',
      borderRadius: '100px',
      borderColor: 'light-grey',
      ':hover': {
        backgroundColor: 'light-grey',
      },
    }}
    {...props}
  />
)

const ServiceHeading = ({ children, ...props }) => (
  <Heading as="h3" fontSize="4" px={3} fontWeight="lighter" letterSpacing="3px" {...props}>
    {children}
  </Heading>
)

const Footer = () => (
  <footer>
    <Box bg="soft-black" display="flex" flexDirection="column" alignItems="center" py="6" color="white">
      <Heading as="h2" variant="headline" mb="4" textAlign="center">
        IDUNN SOFIE RIISE
      </Heading>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['auto', 'auto auto', 'auto auto auto auto'],
          placeItems: 'center',
          gridRowGap: '1rem',

          mb: 4,
        }}
      >
        <ServiceHeading sx={{ borderRight: [null, null, 'solid 1px'] }}>Translation</ServiceHeading>
        <ServiceHeading sx={{ borderRight: [null, null, 'solid 1px'] }}>Subtitling</ServiceHeading>
        <ServiceHeading sx={{ borderRight: [null, null, 'solid 1px'] }}>Localisation</ServiceHeading>
        <ServiceHeading>Copywriting</ServiceHeading>
      </Box>
      <Text mb="5">© Copyright Idunn Sofie Riise Ltd. 2019</Text>
      <Box display="flex" flexDirection="row">
        <IconLink href="mailto:idunnsofieriise@gmail.com">
          <Email />
        </IconLink>
        <IconLink href="https://www.linkedin.com/in/idunn-sofie-riise-b2967a88/">
          <LinkedIn />
        </IconLink>
      </Box>
    </Box>
  </footer>
)

export default Footer
