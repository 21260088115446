export let termsAndConditions = 'terms-and-conditions'
export const service = 'service'
export const contentType = 'content-type'
export const mediaType = 'media-type'
export const genre = 'genre'
export const numberOfMinutes = 'number-of-minutes'
export const wordCount = 'word-count'
export const numberOfEpisodes = 'number-of-episodes'
export const subtitlingServices = 'subtitling-services'
export const industry = 'industry'
export const additionalInformation = 'additional-information'
export const quote = 'quote'
