import React from 'react'
import { Box, Button } from 'rebass'

import Link from './link'
import Burger from './icons/burger'
import { Global } from '@emotion/core'
import Logo from './logo'

const NavLink = props => (
  <Link
    fontSize="3"
    fontFamily="heading"
    color="white"
    display={['none', 'block']}
    letterSpacing="4px"
    mx="1.5rem"
    py="1rem"
    sx={{
      textDecoration: 'none',
      borderBottom: '1px solid transparent',
      ':hover': {
        borderBottom: '1px solid',
        borderBottomColor: 'gold',
      },
    }}
    {...props}
  />
)

function toggleMenu(e) {
  const { classList } = e.currentTarget.parentElement.parentElement
  if (classList.contains('menu-opened')) {
    classList.remove('menu-opened')
  } else {
    classList.add('menu-opened')
  }
}

export default function Navbar() {
  return (
    <>
      <Global styles={{ '.menu-opened a': { display: 'block !important' } }} />
      <nav>
        <Box
          position="relative"
          backgroundColor="soft-black"
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent="space-between"
        >
          <Box flex="1" display="flex" flexDirection="row" justifyContent="space-between">
            <Link display="block" to={'/'} height="46px" width="46px" ml={2} my="5px">
              <Logo />
            </Link>

            <Button backgroundColor="initial" display={['flex', 'none']} p={3} onClick={toggleMenu}>
              <Burger />
            </Button>
          </Box>
          <Box display="flex" flexDirection={['column', 'row']} justifyContent="center">
            <NavLink to="/">HOME</NavLink>

            <NavLink to="/services">SERVICES</NavLink>

            <NavLink to="/about">ABOUT</NavLink>

            <NavLink to="/contact">CONTACT</NavLink>
          </Box>
        </Box>
      </nav>
    </>
  )
}
