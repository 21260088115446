import React from 'react'
import { Box } from 'rebass'

export default function Help({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <title>Help</title>
      <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,76.4-.49.1h-1c-.3-.06-.6-.11-.89-.19a5.54,5.54,0,1,1,2.4.09ZM66,40.09A12.36,12.36,0,0,1,63.1,45a33.11,33.11,0,0,1-5,4.11c-1,.69-1.89,1.41-2.83,2.13a6,6,0,0,0-1.85,2.67c-.2.54-.43,1.08-.64,1.62A3.73,3.73,0,0,1,49.3,58a4.87,4.87,0,0,1-2.53-.4,4.13,4.13,0,0,1-2.27-3.09,7.1,7.1,0,0,1,1.2-5.3,14.56,14.56,0,0,1,3.14-3.32c1.31-1.07,2.66-2.09,4-3.16a10.55,10.55,0,0,0,2.74-3.13,5.87,5.87,0,0,0-.7-6.5,5.4,5.4,0,0,0-3-1.66,9.55,9.55,0,0,0-4.66.08,5.67,5.67,0,0,0-3.61,3c-.47.88-.88,1.78-1.32,2.67A6.49,6.49,0,0,1,41,39a4.47,4.47,0,0,1-3.39,1.09,4.69,4.69,0,0,1-2.14-.58,3.7,3.7,0,0,1-2-3.3,9.68,9.68,0,0,1,2-6.12,15.42,15.42,0,0,1,6-4.86,18.3,18.3,0,0,1,5.69-1.64l1.12-.11h2l1.08.1a21,21,0,0,1,7.71,2,12.84,12.84,0,0,1,5.32,4.55,11.75,11.75,0,0,1,1.81,5.18A11.46,11.46,0,0,1,66,40.09Z" />
    </Box>
  )
}
